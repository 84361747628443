.container.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 200px;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  top: 0;
  padding: 10px;
  position: absolute;
  z-index: 99;
  gap: 20px;
  .floater {
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    background: rgb(35, 35, 35);
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    transition: 100ms ease all;
    &:hover {
      background: lighten(rgb(35, 35, 35), 10%);
    }
    &.close {
      background: rgba(200, 20, 20, 1);
      &:hover {
        background: rgba(255, 20, 20, 1);
      }
    }
  }
  .content {
    width: 100%;
    height: 100%;
    background: rgb(35, 35, 35);
    border-radius: 10px;
    transform: translateY(-200%);
    transition: 100ms ease all;
    position: relative;
    transform-origin: top;
    z-index: -1;
    animation-fill-mode: forwards;
    &.closed {
      animation: close 1s ease;
      animation-fill-mode: forwards;
    }
    &.open {
      animation: open 1s ease;
      animation-fill-mode: forwards;
    }
    @keyframes open {
      0% {
        transform: translateY(-200%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes close {
      0% {
        transform: translateY(0);
        opacity: 1;
      }
      99% {
        transform: translateY(-200%);
        opacity: 0;
      }
      100% {
        display: none;
      }
    }
    .nav {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 10px;
      position: relative;
      .links {
        width: 100%;
        li {
          display: flex;
          width: 100%;
          .link {
            $bg-color: rgb(35, 35, 35);
            border-radius: 5px;
            width: 100%;
            background: $bg-color;
            padding: 10px 20px;
            color: white;
            text-decoration: none;
            font-size: 16px;
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 2px;
              left: 0;
              bottom: -10px;
              background: rgba(255, 255, 255, 0.1);
              position: absolute;
              pointer-events: none;
            }
            &:hover {
              background: lighten($bg-color, 10%);
            }
          }
        }
      }
      li {
        width: 100%;
        padding: 10px;
        &.danger {
          justify-self: flex-end;
        }
        .action {
          font-size: 16px;
          width: 100%;
          padding: 10px 20px;
          border: none;
          outline: none;
          border-radius: 5px;
          cursor: pointer;
          &.streaks {
            background: rgba(255, 86, 35, 0.7);
            &:hover {
              background: rgba(255, 86, 35, 1);
            }
          }
          &.game {
            background: rgba(255, 20, 20, 0.7);
            &:hover {
              background: rgba(255, 20, 20, 1);
            }
          }
        }
      }
    }
  }
}
