.page.levels.level {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .streak-wrapper {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 99;
    pointer-events: none;
    .elmo-fire {
      h2 {
        font-size: 10em;
        text-align: center;
        width: 100%;
        bottom: 0;
        left: 0;
        position: absolute;
        transform: translateY(0);
        opacity: 1;
        z-index: 99;
        transition: 100ms ease all;
        &.inactive {
          opacity: 0;
          transform: translateY(100%);
        }
        &::after {
          content: "Speedster!";
          color: orange;
          font-size: 1.04em;
          text-align: center;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          opacity: 1;
          z-index: -1;
          transition: 100ms ease all;
        }
        &::before {
          content: "Speedster!";
          color: red;
          font-size: 1.06em;
          text-align: center;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          opacity: 1;
          z-index: -1;
          transition: 100ms ease all;
        }
      }
      img {
        pointer-events: none;
        width: 60%;
        max-width: 500px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) translateY(100%);
        position: absolute;
        transition: 200ms ease all;
        &.active {
          transform: translateX(-50%);
        }
      }
    }
  }
  .score-wrapper {
    justify-self: flex-start;
    .score {
      text-align: center;
      color: white;
      font-size: 5em;
    }
  }
  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .clicker-wrapper {
      .clicker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 200px;
        border: none;
        outline: none;
        background: transparent;
        border-radius: 50px;
        cursor: pointer;
        transform: scale(1);
        position: relative;
        &.active {
          animation: scale 200ms ease;
        }
        .click-helper {
          font-size: 2em;
          left: 50%;
          top: 0;
          transform: translate(50%, -50%);
          position: absolute;
          background: $primary;
          padding: 10px;
          border-radius: 10px;
          pointer-events: none;
          &::after {
            $s: 10px;
            $w: 10px;

            content: "";
            width: 0;
            height: 0;
            left: -20%;
            bottom: -20%;
            transform: rotate(-135deg);
            border-left: $s solid transparent;
            border-right: $s solid transparent;
            border-bottom: $w solid $primary;
            position: absolute;
            pointer-events: none;
          }
        }
        .excel-logo {
          max-width: 200px;
          @include remove-user-interaction;
        }

        .hit-anim-container {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          margin: 0;
          padding: 0;
          position: absolute;
          .hit-anim {
            display: block;
            border: none;
            width: 200px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-width: 400px;
            position: absolute;
            z-index: 99;
            &.inactive {
              display: block;
            }
          }
        }

        @keyframes scale {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(0.9);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
    .upgrades-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 10px;
      margin-bottom: 10%;
      position: absolute;
      .click-upgrade-wrapper {
        width: 100%;
        height: 40%;
        max-width: 700px;
        min-width: 100px;
        position: relative;
      }
      .max-upgrade {
        $border-radius: 10px 10px 0 0;

        color: white;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
        outline: none;
        border: none;
        padding: 5px 20px;
        border-radius: $border-radius;
        position: absolute;
        cursor: pointer;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(255, 255, 255, 0.1);
          border-radius: $border-radius;
          z-index: -1;
          position: absolute;
        }
        &::after {
          content: "";
          width: 100%;
          height: 0;
          left: 0;
          bottom: 0;
          background: $primary;
          border-radius: $border-radius;
          z-index: -1;
          position: absolute;
          transition: 100ms ease all;
        }
        &:hover {
          &::after {
            height: 100%;
          }
        }
        &[data-can-afford="true"] {
          &::before {
            background: rgb(30, 100, 30, 1);
          }
          &::after {
            background: rgb(30, 155, 30, 1);
          }
          border-bottom: 2px solid rgba(10, 50, 10, 1);
        }
        &[data-can-afford="false"] {
          &::before {
            background: rgba(200, 50, 50, 1);
          }
          &::after {
            background: rgb(255, 50, 50, 1);
          }
          border-bottom: 2px solid rgba(150, 10, 10, 1);
        }
      }
      .upgrade {
        $border-radius: 10px;

        text-decoration: none;
        color: white;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 700px;
        min-width: 100px;
        height: 40%;
        min-height: 50px;
        max-height: 200px;
        padding: 10px 20px;
        position: relative;
        z-index: +1;
        overflow: hidden;
        border-radius: $border-radius;
        cursor: pointer;
        border: none;
        outline: none;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(255, 255, 255, 0.1);
          border-radius: $border-radius;
          z-index: -1;
          position: absolute;
        }
        &::after {
          content: "";
          width: 100%;
          height: 0;
          left: 0;
          bottom: 0;
          background: $primary;
          border-radius: $border-radius;
          z-index: -1;
          position: absolute;
          transition: 100ms ease all;
        }
        &:hover {
          &::after {
            height: 100%;
          }
        }
        &[data-can-afford="true"] {
          &::before {
            background: rgb(30, 100, 30, 1);
          }
          &::after {
            background: rgb(30, 155, 30, 1);
          }
        }
        &[data-can-afford="false"] {
          &::before {
            background: rgba(200, 50, 50, 1);
          }
          &::after {
            background: rgb(255, 50, 50, 1);
          }
        }
        .text {
          z-index: +2;
        }
      }
    }
  }
}
