.page.home {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title-wrapper {
      .title {
        text-align: center;
        color: white;
        font-size: 5em;
      }
    }
    .action-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .start {
        $border-radius: 10px;

        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 700px;
        min-width: 100px;
        height: 50%;
        min-height: 50px;
        max-height: 200px;
        padding: 10px 20px;
        position: relative;
        z-index: +1;
        overflow: hidden;
        border-radius: $border-radius;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(255, 255, 255, 0.1);
          border-radius: $border-radius;
          z-index: -1;
          position: absolute;
        }
        &::after {
          content: "";
          width: 100%;
          height: 0;
          left: 0;
          bottom: 0;
          background: $primary;
          border-radius: $border-radius;
          z-index: -1;
          position: absolute;
          transition: 100ms ease all;
        }
        &:hover {
          &::after {
            height: 100%;
          }
        }
        .text {
          z-index: +2;
        }
      }
    }
  }
}
