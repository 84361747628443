@mixin remove-user-interaction {
  -webkit-user-drag: none;
  -webkit-user-modify: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-user-focus: none;
  -moz-user-input: none;
  -moz-user-modify: none;
  user-select: none;
  &:-moz-user-disabled {
    -webkit-user-drag: none;
    -webkit-user-modify: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -moz-user-focus: none;
    -moz-user-input: none;
    -moz-user-modify: none;
    user-select: none;
  }
}
