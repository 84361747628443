.flex {
  display: flex;

  &-center {
    align-items: center;

    &-center {
      justify-content: center;
    }
  }
}
