.page.levels {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .title-wrapper {
      .title {
        text-align: center;
        color: white;
        font-size: 5em;
      }
    }

    .actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 1800px;
      padding: 20px;

      .levels {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;

        a {
          text-decoration: none;
        }

        .restart {
          color: white;
          display: block;
          width: 100%;
          background: rgba(200, 50, 50, 1);
          margin-bottom: 10px;
          padding: 10px 20px;
          border-radius: 10px;
          transition: 100ms ease all;
          &:hover {
            background: rgb(255, 50, 50, 1);
          }
        }

        .level {
          width: 200px;
          background: rgb(35, 35, 35);
          padding: 20px;
          position: relative;

          &::after {
            content: "";
            width: 5px;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
          }

          &.active {
            &::after {
              background: green;
            }
          }

          &.disabled {
            $background: rgb(45, 45, 45);
            background: $background;
            &::after {
              background: red;
            }
            &:hover {
              background: $background;
            }
          }
          &:hover {
            background: lighten(rgb(35, 35, 35), 2%);
          }
        }
      }
    }
  }
}



@import "level";
@import "menu";
