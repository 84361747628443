@import "vars";
@import "./mixins/mixins";
@import "./globals/globals";
@import "loaders";

*,
*::before,
*::after {
  font-family: "Lexend", sans-serif;
  box-sizing: border-box;
}

:root {
  margin: 0;
  padding: 0;
}
body {
  
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.container {
  
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  position: absolute;
}

@import "./errors/error_page.scss";

@import "../pages/connector.scss";
